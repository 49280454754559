import {
  ApolloClient,
  ApolloProvider as ApolloProviderClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { ReactNode } from 'react';

const csrfToken = document
  ?.querySelector('meta[name=csrf-token]')
  ?.getAttribute('content');

export const aplolloClient = new ApolloClient({
  link: new HttpLink({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken || '',
    },
  }),
  uri: '/graphql',
  cache: new InMemoryCache(),
});

export default function ApolloProvider({ children }: { children: ReactNode }) {
  return (
    <ApolloProviderClient client={aplolloClient}>
      {children}
    </ApolloProviderClient>
  );
}
