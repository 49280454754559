import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { aplolloClient } from '../../providers/apolo.provider';

const ENVIRONMENT_QUERY = gql(`
    query GetEnvironmentDetails {
      environment {
        environment
      }
    }
  `);

export async function fetchConfig() {
  return aplolloClient.query({ query: ENVIRONMENT_QUERY });
}

export async function initSentry() {
  const config = await fetchConfig();

  const sentryDsn =
    'https://c918db48a71173694f0e5bf4a8a710c8@o358133.ingest.us.sentry.io/4507260976234496';

  const { environment } = config.data.environment;

  if (!sentryDsn || environment === 'development') return;

  // eslint-disable-next-line no-console
  console.info('Initializing Sentry');

  Sentry.init({
    attachStacktrace: true,
    dsn: sentryDsn,
    environment: environment || '-',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

initSentry();
