import { InsuranceTypeEnum, VehicleTypeEnum } from '../__generated__/graphql';
import { ExistingFile } from './document.types';

export enum VehicleFeature {
  DOLLY = 'dolly',
  ROOF_RACK = 'roof-rack',
  LADDER_RACK = 'ladder-rack',
  PIPE_RACK = 'pipe-rack',
  RAMP = 'ramp',
  CARGO_BARRIER = 'cargo-barrier',
  LIFE_GATE = 'life-gate',
  PALLET_JACK = 'pallet-jack',
}

const FEATURES_FOR_CAR = [VehicleFeature.DOLLY, VehicleFeature.ROOF_RACK];
const FEATURES_FOR_MID_SIZED = [VehicleFeature.DOLLY, VehicleFeature.ROOF_RACK];
const FEATURES_FOR_PICKUP_TRUCK = [
  VehicleFeature.DOLLY,
  VehicleFeature.ROOF_RACK,
  VehicleFeature.LADDER_RACK,
  VehicleFeature.PIPE_RACK,
];
const FEATURES_FOR_CARGO_VAN = [
  VehicleFeature.DOLLY,
  VehicleFeature.ROOF_RACK,
  VehicleFeature.LADDER_RACK,
  VehicleFeature.PIPE_RACK,
  VehicleFeature.RAMP,
  VehicleFeature.CARGO_BARRIER,
];
const FEATURES_FOR_CARGO_VAN_HIGH_TOP = [
  VehicleFeature.DOLLY,
  VehicleFeature.ROOF_RACK,
  VehicleFeature.LADDER_RACK,
  VehicleFeature.PIPE_RACK,
  VehicleFeature.RAMP,
  VehicleFeature.CARGO_BARRIER,
];
const FEATURES_FOR_10BOX_TRUCK = [
  VehicleFeature.DOLLY,
  VehicleFeature.ROOF_RACK,
  VehicleFeature.LADDER_RACK,
  VehicleFeature.PIPE_RACK,
  VehicleFeature.RAMP,
  VehicleFeature.CARGO_BARRIER,
  VehicleFeature.PALLET_JACK,
  VehicleFeature.LIFE_GATE,
];
const FEATURES_FOR_16BOX_TRUCK = [
  VehicleFeature.DOLLY,
  VehicleFeature.ROOF_RACK,
  VehicleFeature.LADDER_RACK,
  VehicleFeature.PIPE_RACK,
  VehicleFeature.RAMP,
  VehicleFeature.CARGO_BARRIER,
  VehicleFeature.PALLET_JACK,
  VehicleFeature.LIFE_GATE,
];
const FEATURES_FOR_20BOX_TRUCK = [
  VehicleFeature.DOLLY,
  VehicleFeature.ROOF_RACK,
  VehicleFeature.LADDER_RACK,
  VehicleFeature.PIPE_RACK,
  VehicleFeature.RAMP,
  VehicleFeature.CARGO_BARRIER,
  VehicleFeature.PALLET_JACK,
  VehicleFeature.LIFE_GATE,
];

export const FEATURES_GROUPED_BY_VEHICLE_TYPE = [
  {
    vehicleType: VehicleTypeEnum.Car,
    features: FEATURES_FOR_CAR,
  },
  {
    vehicleType: VehicleTypeEnum.MidSized,
    features: FEATURES_FOR_MID_SIZED,
  },
  {
    vehicleType: VehicleTypeEnum.PickupTruck,
    features: FEATURES_FOR_PICKUP_TRUCK,
  },
  {
    vehicleType: VehicleTypeEnum.CargoVan,
    features: FEATURES_FOR_CARGO_VAN,
  },
  {
    vehicleType: VehicleTypeEnum.CargoVanHighTop,
    features: FEATURES_FOR_CARGO_VAN_HIGH_TOP,
  },
  {
    vehicleType: VehicleTypeEnum.BoxTruck_10,
    features: FEATURES_FOR_10BOX_TRUCK,
  },
  {
    vehicleType: VehicleTypeEnum.BoxTruck_16,
    features: FEATURES_FOR_16BOX_TRUCK,
  },
  {
    vehicleType: VehicleTypeEnum.BoxTruck_20,
    features: FEATURES_FOR_20BOX_TRUCK,
  },
];

type Insurance = {
  id?: string;
  insurance_type: InsuranceTypeEnum;
  expiration: Date;
  documents: ExistingFile[];
};

export type VehicleDetailsInput = {
  vehicleId?: string;
  insuranceId?: string;
  make: string;
  model: string;
  vin: string;
  license_plate_number: string;
  license_plate_state: string;
  license_plate_expiration?: Date;
  insurance_type?: InsuranceTypeEnum;
  insurance_expiration?: Date;
};

export type Vehicle = {
  id?: string;
  vehicle_type: VehicleTypeEnum;
  features: VehicleFeature[];
  make: string;
  model: string;
  vin: string;
  license_plate_number: string;
  license_plate_state: string;
  license_plate_expiration: Date;
  insurance: Insurance;
  images: ExistingFile[];
};
