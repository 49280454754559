import { Label } from '@dispatch-ui/react';
import { cn } from '@dispatch-ui/utils';

type LabelFormProps = {
  hasError?: boolean;
  label?: string;
  labelClassName?: string;
  labelHelp?: string;
  required?: boolean;
};

function LabelForm({
  hasError = false,
  label,
  labelClassName = 'font-semibold',
  labelHelp,
  required,
}: LabelFormProps) {
  const labelClassNameBase = cn('tracking-wide', hasError ? 'text-red' : '');
  const labelHelpClassName = cn(
    'tracking-wide text-xs mt-2',
    hasError ? 'text-red' : 'text-gray-500',
  );

  return (
    <div className="flex flex-col space-y-0">
      {label && (
        <div className="flex">
          {required ? (
            <div
              className={`${labelClassNameBase} ${labelClassName} leading-5 text-red`}
            >
              *&nbsp;
            </div>
          ) : (
            <div />
          )}
          <Label
            className={`${labelClassNameBase} ${labelClassName} leading-5`}
          >
            {label}
          </Label>
        </div>
      )}
      {labelHelp && <Label className={labelHelpClassName}>{labelHelp}</Label>}
    </div>
  );
}

export default LabelForm;
