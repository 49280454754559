import { Checkbox, Label } from '@dispatch-ui/react';

export type ChckboxItemProps = {
  value: string;
  text: string;
  secondaryText?: string;
};

type CustomCheckboxGroupProps = {
  item: ChckboxItemProps;
  onCheckedChange: (checked: boolean) => void;
  checked: boolean;
};

function CustomCheckbox({
  item,
  onCheckedChange,
  checked = false,
  ...rest
}: CustomCheckboxGroupProps): JSX.Element {
  return (
    <div
      {...rest}
      key={item.value}
      className="border-border flex items-center space-x-2 rounded-sm border-2 p-3"
    >
      <Checkbox
        data-testid={`${item.value}-checkbox-input`}
        value={item.value}
        id={item.value}
        onCheckedChange={onCheckedChange}
        checked={checked}
      />
      <div className="flex flex-col space-y-2">
        <Label
          className="text-radio-button cursor-pointer"
          htmlFor={item.value}
        >
          {item.text}
        </Label>
        {item.secondaryText && (
          <Label
            className="text-secondary text-xs, cursor-pointer text-gray-500"
            htmlFor={item.value}
          >
            {item.secondaryText}
          </Label>
        )}
      </div>
    </div>
  );
}

export default CustomCheckbox;
